import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";
import { Panel } from "primereact/panel";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
import { confirmAlert } from "react-confirm-alert";
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// C:\WORK\eBetting\admin\src\service\community.service.tsx
import { AgentService } from "../service/agent.service";
// import { HelpStatus, OpenUserDetail, convertDate } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";
import { converAgentStatus, numTomoeny, convertAgentBenefitBalanceText, HelpStatus, convertDate } from "../utility/help";
import { modes } from "react-transition-group/SwitchTransition";
import { parseConfigFileTextToJson, updateIf } from "typescript";



enum Mode {
    none = 'none', 
    view = 'view', 
    wirte = 'wirte' 
}

interface State {
  notes: any[];
  selTemplate: any;
  template: any[];
  note: any;
  activePage: number;
  maxCount: number;
  selectBox: any[];
  dropdownItem: any;
  seach: any;

  mode : string,

}

export class   AgentNote extends Component<any, State> {
  agentService = new AgentService();

  constructor(props: any) {
    super(props);

    this.state = {
        mode : Mode.none ,
        notes: [],
        template: [],
        selTemplate: "",
        activePage: 1,
        maxCount: 1,
        note: {
            mode: "user",
            del: "n",
            contents: "  ",
            title: "",
            userId: "",
            ref: { contents: "<div></div>" },

        },
        seach: "",
        dropdownItem: null,
        selectBox: [
            { label: "선택", value: null },
            { label: "아이디", value: "id" },
            { label: "제목", value: "title" },
            // { label: "내용", value: "text" },
        ],
    };
  }

  componentDidMount() {
    this.handleUserNote(1);
  }

  handleUserNote = async(skip: any) => {
    this.setState({ activePage: skip });
    let data = await this.agentService.admin_get_agent_note_list(skip).then(data => data)

    if (data.status == "success") {
      this.setState({ notes: data.notes, maxCount: data.maxCount });
    }
  };

  handleReadNote = async(_idx)=>{
    await this.agentService.admin_read_agent_note(_idx).then(data => data)
  }




  handleDelHelp = async(_idx) => {
     let data = await this.agentService.admin_del_agent_note(_idx).then(data => data)
     
    if (data.status == "success") {
        confirmAlert({
           title: "쪽지함",
           message: "쪽지함 삭제되었습니다.",
           buttons: [
             {
               label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
       confirmAlert({
         title: "쪽지함",
         message: "쪽지함 삭제 실패.",
         buttons: [
           {
             label: "확인",
             onClick: () => {
               window.location.reload();
             },
           },
         ],
       });
      }
  };

  render() {
    let items = this.state.notes;

    const actionTemplate = (rowData, column) => {
      return (
        <div>
          <Button
            icon="pi-md-edit"
            onClick={() => {
              this.setState({ note : rowData, mode  :  Mode.view });
              this.handleReadNote(rowData._id)
            }}
          ></Button>
        </div>
      );
    };

    const RenderHelp = () => {
        
        if(this.state.mode  === Mode.none){
            return <></>
        }



        if (this.state.mode  === Mode.view &&  this.state.note.userId !== null) {
            return (
            <div className="p-col-12 p-md-8">
                <div className="card">
                <div className="p-col-12">
                    <span className="p-float-label">
                    <InputText
                        id="in"
                        style={{ width: "70%"}}
                        value={this.state.note.title}
                        onChange={(e: any) => {
                            this.state.note.title = e.target.value;
                            this.setState({ note: this.state.note });
                        }}
                        readOnly
                    />
                    <label htmlFor="in">Title</label>
                    </span>
                </div>
                <div className="p-col-12">
                    <Panel header="작성 시간">
                    <p>{convertDate(this.state.note.regDate)}</p> 
                    </Panel>
                </div>
                <div className="p-col-12">
                    <Panel header="내용">
                      <div dangerouslySetInnerHTML={{ __html:this.state.note.text }}></div>
                    </Panel>
                </div>

                <div className="p-col-12">
                    <div className="p-grid">

                    <div className="p-col-6">

                    </div>
                    <div className="p-col-4">

                        <Button
                        label="삭제"
                        color="secondary"
                        onClick={() => {
                            this.handleDelHelp(this.state.note._id);
                        }}
                        />
                    </div>


                    </div>
                </div>
                </div>
            </div>
            );
        }
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">쪽지 관리</div>
                <div className="p-md-6">
                  <div className="p-grid form-group">
 
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-4">
              <div className="card">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(page) => {
                    this.handleUserNote(page)
                  }}
                ></CustomPagination>
                <DataTable
                  value={items}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "left" }}
                    header="제목"
                    body={(rowData: any, column: any) => {
                      return <div style={{ color: rowData.color }} onClick={()=>{
                        this.handleReadNote(rowData._id);
                        this.setState({ note : rowData, mode  :  Mode.view });
                      }}>  {rowData.title}</div>;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="상태"
                    body={(rowData: any, column: any) => {

                      if (rowData.del == 'y') {
                        return (
                          <>
                            {HelpStatus(rowData.status)} 
                          </>
                        )
                      }
                      if (rowData.isRead == 'n') {
                        return (<>미확인</>)
                      }
                      
                      return <>확인</>;
                    }}
                  />
                  {/* <Column
                    body={actionTemplate}
                    style={{ textAlign: "center", width: "8em" }}
                  /> */}
                </DataTable>
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(page) => {
                    this.handleUserNote(page)
                  }}
                ></CustomPagination>
              </div>
            </div>

            {RenderHelp()}
          </div>
        </div>
      </div>
    );
  }
}
