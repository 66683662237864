import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers
} from "../../utility/help";
import { AnyTxtRecord } from "dns";

interface MatchProps {
  mode: any;
  details: any;
  bet: any;
  key: any;

  // details: any;
}

export class BetMatchTable extends Component<MatchProps> {
  constructor(props: MatchProps) {
    super(props);
    this.state = {
      active: 0,
      deposits: [],
      details: [],

      page: 0,
      field: ""
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount = () => {};

  // header = (up, down) => {
  //   return (
  //     <div>
  //       <span>{up}</span>
  //       <br />
  //       <span>{down}</span>
  //       <br />
  //     </div>
  //   );
  // };

  handlePageChange = (e: any) => {
    console.log(e);
    this.setState({ active: e });
  };

  render = () => {
    const mode = this.props.mode;
    const bets = this.props.bet == undefined ? [] : [this.props.bet];
    const details = this.props.details == undefined ? [] : this.props.details;
    const tdetails = Object.values(details);

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };
    /*if (bets.balance >= 300000) {
      //customHeaderStyle["backgroundColor"] = "oringe";
      customHeaderStyle = { backgroundColor: "red", textAlign: "center" };
    }*/

    return (
      <div>
        <DataTable
          value={tdetails}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          scrollable={true}
          style={{ marginTop: "30px", width: "1600px" }}
        >
          <Column
            style={{ textAlign: "center" }}
            // style={{ textAlign: "center", width: "10%" }}
            header="게임시간"
            body={(rowData: any, column: any) => {
              return <div>{convertDate(rowData.dateOfMatch)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            // style={{ textAlign: "center", width: "120px" }}
            field="bet"
            header="스포츠"
            body={(rowData: any, column: any) => {
              return <div>{rowData.sportName}</div>;
            }}
          />
          <Column
            // style={{ textAlign: "center", width: "120px" }}
            style={{ textAlign: "center" }}
            field="win"
            header="리그"
            body={(rowData: any, column: any) => {
              return <div>{rowData.tournamentName}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="홈"
            body={(rowData: any, column: any) => {
              return <div>{rowData.homeName}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="원정"
            body={(rowData: any, column: any) => {
              return <div>{rowData.awayName}</div>;
            }}
          />

          <Column
            // style={{ textAlign: "center", width: "120px" }}
            style={{ textAlign: "center" }}
            header="게임 타입"
            body={(rowData: any, column: any) => {
              return <div>{rowData.outComeName}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            // style={{ textAlign: "center", width: "120px" }}
            header="배팅타입"
            body={(rowData: any, column: any) => {
              return <div>{rowData.oddsName}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            // style={{ textAlign: "center", width: "120px" }}
            header="기준"
            body={(rowData: any, column: any) => {
              return <div>{convertSpecifiers(rowData.specifiers)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            // style={{ textAlign: "center", width: "150px" }}
            header="배당"
            body={(rowData: any, column: any) => {
              return <div>{rowData.odds}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            // style={{ textAlign: "center", width: "150px" }}
            header="결과"
            body={(rowData: any, column: any) => {
              return <div>{converBetStatus(rowData.status)}</div>;
            }}
          />
        </DataTable>

        <DataTable
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          value={bets}
          className="success"
          scrollable={true}
          style={{ width: "1600px" }}
        >
          <Column
            style={{ textAlign: "center" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="배팅일"
            body={(rowData: any, column: any) => {
              return <div>{convertDate(rowData.regDate)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            field="id"
            header="ID"
          />
          <Column
            style={{ textAlign: "center" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            field="agent"
            header="총판"
          />

          <Column
            style={{ textAlign: "center" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="총판 트리"
            field="agent_tree"
          />

          <Column
            style={{ textAlign: "center" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="배팅금"
            body={(rowData: any, column: any) => {
              return <div>{numTomoeny(rowData.balance)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="예상당점금"
            body={(rowData: any, column: any) => {
              return <div>{numTomoeny(rowData.beforeWinBalance)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "150px" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="상태"
            body={(rowData: any, column: any) => {
              return <div>{converBetStatus(rowData.status)}</div>;
            }}
          />
        </DataTable>
      </div>
    );
  };
}
