import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentDetail, convertAgentBenefitBalanceText, ConvertCalendar, GetTodayToString, GetYesterOnlyDay} from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { AgentService } from "../service/agent.service";

interface Props {
    agentId?: string;
}

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    options: any[];
    options_type: string;
    begin: any;
    end: any;

    agentInfo: any[];

    level : number
    benefits : any;
    totalBalance : any;
}

export class CalculateSubAgentKcrown extends Component<Props, BalanceState> {
    balanceService: BalanceService = new BalanceService();
    agentService: AgentService = new AgentService();

    constructor(props: Props) {
        super(props);

        let today = GetYesterOnlyDay();

        // const today = new Date();
        // const begin = new Date(
        //     `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
        // );

        // today.setMonth(today.getMonth() + 1);

        // const end = new Date(
        //     `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
        // );

        this.state = {
            begin: today.begin,
            end: today.end,
            findText: "",
            searchText: "",
            selectItem: {},

            options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

            options_type: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
            level : 1,
            benefits : [],
            totalBalance : {}

        };

        this.handlegetSubAgentCalculate(this.props.agentId,  today.begin,  today.end);
        this.handleGetAgentBenefit()
    }

    
    
    handleGetAgentBenefit = () => {
        this.agentService.agent_get_benefits().then(s => {
        if (s.status == "success") {
            this.setState({ benefits: s.benefits });
        }
        });
    };

    handlegetSubAgentCalculate(agentId ,begin: any, end: any) {
        this.balanceService.admin_get_sub_agent(agentId, begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
            if (data.status == "success") {

             
                let agents = JSON.parse(JSON.stringify(data.agentCombine)).sort(function (a, b) { return b.level - a.level; });

                const downBalance = {}

                for (let info of agents) {
                    let losingPersistent = 0
                    let CasionPersistent = 0
                    let SlotPersistent = 0

                    const TotlalBalance = (info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance) -
                        (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance) +
                        (info.totalYDReadInBalance - info.totalRealInBalance)

                    for (let tree of info.agent_tree.split('|').reverse()) {
                        if (tree == null || tree == '') {
                            continue;
                        }
                        let main = data.agentCombine.find(s => s.agent == tree)
                        if (main == null) {
                            continue;
                        }
                        if (main.totalLosingBalance == null) {
                            main.totalLosingBalance = 0
                        }
                        main.totalLosingBalance += ((info.realDepositBalance - info.realWithdrawBalance)) * (main.benefitLosing - losingPersistent)

                        if (main.totalSlotBalance == null) {
                            main.totalSlotBalance = 0
                        }

                        if (downBalance[tree] == null) {
                            downBalance[tree] = 0
                        }

                        main.totalSlotBalance += ((info.totalRealSlotBet / 100)) * (main.benefitSlotRolling - SlotPersistent)

                        downBalance[tree] += ((info.totalRealSlotBet / 100)) * (main.benefitSlotRolling - SlotPersistent)


                        if (main.totalCasinoBalance == null) {
                            main.totalCasinoBalance = 0
                        }
                        main.totalCasinoBalance += ((info.totalRealCasinoBet / 100)) * (main.benefitCasinoRolling - CasionPersistent)
                        downBalance[tree] += ((info.totalRealCasinoBet / 100)) * (main.benefitCasinoRolling - CasionPersistent)





                        if (main.totalBalance == null) {
                            main.totalBalance = 0
                        }

                        if (main.totalCasion == null) {
                            main.totalCasion = 0
                        }
                        if (main.totalSlot == null) {
                            main.totalSlot = 0
                        }


                        main.totalBalance += (((
                            (TotlalBalance) -
                            (downBalance[tree]))) *
                            (main.benefitLosing - losingPersistent)) / 100


                        main.totalSlot += (main.totalRealSlotBet * (main.benefitSlotRolling - SlotPersistent)) / 100



                        downBalance[tree] = 0


                        losingPersistent = main.benefitLosing

                        CasionPersistent = main.benefitCasinoRolling
                        SlotPersistent = main.benefitSlotRolling
                    }

                }

                

                data.agentCombine.sort(function (a: any, b: any) {
                    if (a.agent_tree < b.agent_tree) return -1;
                    if (b.agent_tree < a.agent_tree) return 1;
                    return 0;
                })



                  this.setState({ agentInfo: data.agentCombine , level : data.level, totalBalance : 0});
      
            }
        });
    }

    componentDidMount() {
    }

    render() {

        const rowClass = (data) => {

            if (data.level === 1) return { 'row-level1': data.level === 1 }
            if (data.level === 2) return { 'row-level2': data.level === 2 }
            if (data.level === 3) return { 'row-level3': data.level === 3 }


            return { 'row-level4': data.level === 4 }
        }



        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title"> {this.props.agentId}</div>
                                <div className="p-grid form-group">

                                    <div className="p-col-8">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                // placeholder="Begin"
                                                // showIcon={true}
                                                // dateFormat="yy-mm-dd"
                                                // view="date"
                                                // value={this.state.begin}
                                                // onChange={(e) => this.setState({ begin: e.value })}
                                                // showWeek={false}
                                                // locale={ConvertCalendar()}
                                                // showTime
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                showWeek={false}

                                                value={new Date(this.state.begin)}
                                                onChange={(e: any) => this.setState({ begin: e.value.toLocaleString('sv-SE') })}

                                                showTime
                                                locale={ConvertCalendar()}

                                                />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Calendar
                                                // placeholder="Begin"
                                                // showIcon={true}
                                                // dateFormat="yy-mm-dd"
                                                // view="date"
                                                // value={this.state.end}
                                                // onChange={(e) => this.setState({ end: e.value })}
                                                // showWeek={false}
                                                // locale={ConvertCalendar()}
                                                // showTime
                                                placeholder="End"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                showWeek={false}
                                                value={new Date(this.state.end)}
                                                onChange={(e: any) => this.setState({ end : e.value.toLocaleString('sv-SE') })}

                                                // onChange={(e: any) =>
                                                //   this.setState({ end: e.value.toLocaleString() })
                                                // }
                                                showTime
                                                locale={ConvertCalendar()}
                                                />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetSubAgentCalculate(this.props.agentId, this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-4">
                                    <div className="card-title">에이전트 벨런스 </div>
                                    {
                                        Object.values(this.state.benefits).map((benefit : any) => {
                                        return (
                                            <div className="p-grid">
                                            <div className="p-col-12 p-md-2">
                                            <label htmlFor="input">{convertAgentBenefitBalanceText(benefit.benefit_type)}</label>
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                        
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                            <label htmlFor="input">
                                            {benefit.percent}
                                            </label>
                                            </div>
                                
                                        
                                            </div>

                                        )
                                        })
                                    } 
                                </div>
                            </div>
                            </div>
                            <div className="card">
                              
                                            <DataTable
                                            paginatorPosition="both"
                                            scrollable={true}
                                            value={this.state.agentInfo}
                                            rows={999}
                                            sortOrder={-1}
                                            style={{  padding: '0px'}}
                                            rowClassName={rowClass}
                                        >

                                            {
                                                this.state.level <= 1 && (
                                                    <Column
                                                    style={{
                                                        textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px" 
                                                    }}
                                                    headerStyle={{ textAlign: "center",background: '#27765c' , width: "80px" , color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                                    header="본사"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 1) return (
                                                            <div>
                                                                <div>{rowData.agent}</div>
                                                                <div>{rowData.nick}</div>
                                                            </div>
                                                        )
                                                        return <></>;
                                                        
                                                        
                                                    }}
                                                /> 
                                                )
                                            }
                                            {
                                                this.state.level <= 2 && (
 
                                        
                                        <Column
                                                style={{
                                                    textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px" 
                                                }}
                                                headerStyle={{ textAlign: "center",background: '#27765c' , width: "80px" , color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                                header="부본사"
                                                body={(rowData: any, column: any) => {
                                                    if (rowData.level === 2) return (
                                                        <div>
                                                            <div>{rowData.agent}</div>
                                                            <div>{rowData.nick}</div>
                                                        </div>
                                                    )
                                                    return <></>;
                                                    
                                                    
                                                }}
                                            />
                                                )
                                            }
                                                {
                                                    this.state.level <=  3 && (
    
                                                        <Column
                                                            style={{
                                                                textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px" 
                                                            }}
                                                            headerStyle={{ textAlign: "center",background: '#27765c' , width: "80px" , color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                                            header="총판"
                                                            body={(rowData: any, column: any) => {
                                                                if (rowData.level === 3) return (
                                                                    <div>
                                                                    <div>{rowData.agent}</div>
                                                                    <div>{rowData.nick}</div>
                                                                </div>
                                                                        )
                                                                return <></>;
                                                                
                                                                
                                                            }}
                                                        />
                                                    )
                                            }
                                            
                                            {
                                                this.state.level <=  4 && (

                                              <Column
                                                style={{
                                                    textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px" 
                                                }}
                                                headerStyle={{ textAlign: "center",background: '#27765c' , width: "80px" , color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                                header="대리점"
                                                body={(rowData: any, column: any) => {
                                                    if (rowData.level === 4) return (
                                                        <div>
                                                            <div>{rowData.agent}</div>
                                                            <div>{rowData.nick}</div>
                                                        </div>
                                                    )
                                                    return <></>;
                                                    
                                                    
                                                }}
                                            />
                                                )
                                                }
                                        
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                headerStyle={{ textAlign: "center",background: '#27765c' , color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>유저 입금</div><div>총판 지급</div><div>센터 지급</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>
                                                    </div>;
                                                }}
                                            />
    
    
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                headerStyle={{ textAlign: "center",background: '#27765c' ,color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>유저 출금</div><div>총판 회수</div><div>센터 회수</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>
                                                    </div>;
                                                }}
                                            />
    
    
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                headerStyle={{ textAlign: "center",background: '#27765c' , color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>본유저 입금</div><div>본총판 지급</div><div>본센터 지급</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.realDepositBalance)}</div>
                                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.realGiveDepositBalance)}</div>
                                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.realAdmindeposit)}</div>
                                                    </div>;
                                                }}
                                            />
    
    
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                headerStyle={{ textAlign: "center",background: '#27765c' ,color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>본유저 출금</div><div>본총판 회수</div><div>본센터 회수</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.realWithdrawBalance)}</div>
                                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.realGiveWithdrawBalance)}</div>
                                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.realAdminwithdraw)}</div>
                                                    </div>;
                                                }}
                                            />
{/*                                             
                                            ((info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance) -
                                (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance) +
                                (info.totalYDReadInBalance - info.totalRealInBalance)) - */}
    
    
    
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8'  , width: "80px"}}
                                                headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>유저 수익</div><div>총판 수익</div><div>센터 </div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                                                        <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                                                        <div style={{ color: rowData.totalAdmindeposit - rowData.totalAdminwithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalAdmindeposit - rowData.totalAdminwithdraw)))}</div>
                                                    </div>;
                                                }}
                                            />
    
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                // header={<div>유저 보유 { process.env.REACT_APP_API_WINLOSE}</div>}
                                                header={<div><div>금일 보유금</div><div>전일 보유금</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'green' }}>{numTomoeny(rowData.totalInBalance)}</div>
                                                        <div style={{ color: 'green' }}>{numTomoeny(rowData.totalYDInBalance)}</div>
                                                    </div>;
                                                }}
                                            />

    
                                     

                                            {
                                                process.env.REACT_APP_API_WINLOSE === 'Y' ? (
                                                    <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                        headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>Bet</div><div>Win</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            return <div>
                                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                                                            </div>;

                                                        }}
                                                    />
                                                  
                                                    ) : (
                                                        <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                        headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>Bet</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            return <div>
                                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                            </div>;

                                                        }}
                                                    />

                                                )
                                            
                                            }
                                            
                                            {
                                                process.env.REACT_APP_API_WINLOSE === 'Y' && (
                                                    <Column
                                                        headerStyle={{ textAlign: "center",background: '#27765c', width: "80px", color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "80px"  }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>총회원 루징</div><div>BET-WIN</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            return <div>
                                                            <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalSlotBet - rowData.totalSlotWin)}</div>
                                                            </div>;
                                                        }}
                                                    />
                                                  
                                                    ) 
                                            }
                                            {
                                                process.env.REACT_APP_API_BALANCE_LOSING  === 'Y' && (
                                                    
                                                    <Column
                                                        headerStyle={{ textAlign: "center",background: '#27765c', width: "80px", color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>총회원 루징</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            const total = 
                                                           (rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit) -
                                                                (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw) +
                                                                (rowData.totalYDInBalance - rowData.totalInBalance)
                                                            return <div>
                                                            <div style={{ color: total > 0 ? 'red' : 'blue' }}>{numTomoeny(total)}</div>
                                                            </div>;
                                                        }}
                                                    />
                                                ) 
                                            }   

                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>롤링</div><div>프로테이지</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                       <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.totalSlotBalance)}</div>
                                                    <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                                    </div>;
                                                }}
                                            />


                                            

                                            {
                                                process.env.REACT_APP_API_CALCULATE_CASINO === 'Y' && (
                                                    <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                        headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>카지노 롤링</div><div>프로테이지</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            return <div>
                                                            <div style={{ color:  '#ffc107' }}>{numTomoeny(rowData.totalCasinoBalance)}</div>
                                                            <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                                                            </div>;
                                                        }}
                                                    />
                                                )
                                            }

                                            
                                            {
                                                process.env.REACT_APP_API_WINLOSE === 'Y' ? (
                                                    <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                        headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>정산금 (%)</div></div>}
                                                        body={(rowData: any, column: any) => {
            
                                                            // if(rowData.benefitSlotRolling === 0){
                                                            //     return (
                                                            //         <div>0 ({rowData.benefitSlotRolling}%)</div>
                                                            //     )
                                                            // }
                                                            const total = rowData.totalBalance
            
                                                            return <div>
                                                                    <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin - rowData.exchangeBalance > 0 ? 'red' : 'blue' }}>
                                                                        {numTomoeny(total)}</div>
                                                                    <div>{rowData.benefitLosing} %</div>
                                                                
                                                            </div>;
                                                        }}
                                                    />
                                                ) : (
                                                    <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                        headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>정산금 (%)</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            const total = rowData.totalBalance

                                                            
                                                            return <div>
                                                                    <div >{numTomoeny(total)}</div>
                                                                    <div>{rowData.benefitLosing} %</div>
                                                            </div>;
                                                        }}
                                                    />

                                            )
                                            }

                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px"}}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>파트너 충전 {numTomoeny(this.state.totalBalance['realAgentChangeBalance'])} 
                                                    </div><div>파트너 환전{numTomoeny(this.state.totalBalance['realAgentExchangeBalance'])}
                                                    </div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                       <div style={{ color:  '#ffc107' }}>{numTomoeny( rowData.realAgentChangeBalance)}</div>
                                                    <div style={{ color: 'green' }}>{numTomoeny(rowData.realAgentExchangeBalance)}</div>
                                                    </div>;
                                                }}
                                            />
                                        </DataTable>
                                  

 
                  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
