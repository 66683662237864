import axios from "axios";

import { AxiosMag } from "./axios.service";

export class BalanceService {
  agent_deposit_to_user = async (parameter) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_deposit_to_user", parameter);
  };

  agent_withdraw_to_user = async (parameter) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_withdraw_to_user_for_main_service", parameter);
  };

  agent_balance_log = async (
    skip: number,
    findText: string,
    searchText: string,
    begin: string,
    end: string,
    view : string
  ) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_balance_log", {
      skip,
      findText,
      searchText,
      begin,
      end,
      view
    });
  };

  agent_deposit_to_agent = async (parameter) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_deposit_to_agent", parameter);
  };


  agent_withdrawt_to_agent = async (parameter) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_withdrawt_to_agent", parameter);
  };


  admin_get_sub_agent = async (agentId: string, begin: string, end: string) => {
    return await AxiosMag.SendMessageByPost("/balance/admin_get_sub_agent_time", {
      agentId: agentId,
      begin: begin,
      end: end,
    });
  };





}
