import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

// C:\WORK\eBetting\admin\src\share\log\balanceLog.js

import { AgentService } from "../service/agent.service";
import { SubPaging } from "../share/sub-paging";

import * as AgentData from "../interface/agent";

import {
  numTomoeny,
  convertDate,
  convertAgentLogText,
  convertAgentBalanceText,
  OpenUserDetail,
  OpenAgentDetail
} from "../utility/help";

interface AgentState {
  logs: any;
  activePage: any;
  maxCount: any;
}

export class AgentBalanceLog extends Component<any, AgentState> {
  AgentService: AgentService;
  constructor(props: any) {
    super(props);

    this.state = {
      logs: [],
      maxCount: 1,
      activePage: 1
    };

    //this.handleAgentBalance = this.handleAgentBalance.bind(this);

    this.AgentService = new AgentService();
  }

  componentDidMount() {
    this.handleAgentBalanceLog(0);
  }

  handleAgentBalanceLog = (page: any) => {
    this.AgentService.agent_get_agnet_balance_log(page).then((s: any) => {
      this.setState({
        logs: s.logs,
        maxCount: s.maxCount
      });
    });
  };
  handleMoveSel = (page: any) => {
    this.setState({ activePage: page });
    this.handleAgentBalanceLog(page);
  };

  render = () => {
    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">에이전트 벨런스</div>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={page => {
                    this.handleMoveSel(page);
                  }}
                />
                <DataTable
                  value={this.state.logs}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="타입"
                    body={(agent: any) => {
                      return <div>{convertAgentLogText(agent.logType)}</div>;
                    }}
                    field="agent"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="벨런스타입"
                    body={(agent: any) => {
                      return (
                        <div>{convertAgentBalanceText(agent.balanceType)}</div>
                      );
                    }}
                    field="point"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="에이전트"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agent);
                          }}
                        >
                          {agent.agent}
                        </div>
                      );
                    }}
                    field="level"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="유저 아이디"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenUserDetail(agent.id);
                          }}
                        >
                          {agent.id}
                        </div>
                      );
                    }}
                    field="nick"

                    // sortFunction={}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="충/환전/롤링/관리자"
                    body={(agent: any) => {
                      return agent.balance;
                    }}
                    field="balance"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="남은금액"
                    body={(agent: any) => {
                      return numTomoeny(agent.afterbalance);
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="등록일"
                    body={(agent: any) => {
                      return convertDate(agent.regdate);
                    }}
                    field="agent_tree"
                  />
                </DataTable>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={page => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
