import React, { Component,useState, useEffect, useRef } from 'react';

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
// import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
// import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';

import { OverlayPanel } from 'primereact/overlaypanel';

import { confirmAlert } from "react-confirm-alert"; // Import

import { UserService } from "../../service/user.service";
import { BalanceService } from "../../service/balance.service";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
  ConvertNick,
  UUID
} from "../../utility/help";

let CUSTOM = process.env.REACT_APP_API_CUSTOM;


interface Props {

  //dataType: any;
  level : any;
  mode: string;
  userTableValue?: any;
  handleUseStauseChange: (userId: string, stause: string) => any;
}

interface State {
  balances: any
  balance: any
  userID: any
  mode: any
  userTableValue?: any;
  code :string
}



export class UserTable extends Component<Props, State> {
  userService = new UserService();
  balanceService: BalanceService = new BalanceService();

  giveBalance = React.createRef() as any;

  // };

  constructor(props: Props) {
    super(props);
    this.state = {
      code : UUID(),
      mode : 'charge',
      userID : '',
      balance : 0,
      balances: [
        { name: "10,000", value: 10000 },
        { name: "50,000", value: 50000 },
        { name: "100,000", value: 100000 },
        { name: "500,000", value: 500000 },
        { name: "1,000,000", value: 1000000 },
        { name: "초기화 ", value: 0 },
      ],
    }

  }



  handleDoDeposit() {
    confirmAlert({
      title: "지급",
      message: "해당 벨런스를 유저에게 지급하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              // .agent_deposit_to_user(this.state.balance, this.state.userID)
              .agent_deposit_to_user({balance: this.state.balance, id : this.state.userID, code:  this.state.code})
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {

                          this.handleBalanceUpdate(this.state.userID);
                           this.setState({balance : 0, code : UUID()})
                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "time") {
                  confirmAlert({
                    title: "지급",
                    message: "10초 후에 다시 시도해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  handleDoWithdraw() {
    confirmAlert({
      title: "회수",
      message: "해당 벨런스를 유저에게 회수하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_withdraw_to_user({balance: this.state.balance, id : this.state.userID, code:  this.state.code})

              .then((s: any) => {
                if (s.status === "success") {
                  confirmAlert({
                    title: "회수",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                          window.location.reload()

                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "time") {
                  confirmAlert({
                    title: "지급",
                    message: "10초 후에 다시 시도해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "회수",
                    message: "유저 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "회수",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }
  componentDidMount() {
    //    this.GetUserList(0, this.props.dataType, 1);
  }

  // GetUserList = (page: any, type: any, sort: any) => {
  //   console.log(page, sort);
  //   this.userService.get_user_list(page, type, sort).then(data => {
  //     this.setState({ userTableValue: data.users });
  //   });
  // };

  handleBalanceUpdate = (id: string) => {
    let user = this.props.userTableValue.find((data) => data.id == id);
    if (user != null) {
      user.refresh = true;
      this.setState({})
    }

    this.userService.get_in_game_balance(id).then((s: any) => {
      if (s.status === "success") {
        let user = this.props.userTableValue.find((data : any) => data.id == s.id);
        if (user != null) {
          user.balance = Number(s.balance);
          user.in_balance = 0
          user.refresh = false;
          this.setState({ });

        }
      }
      let user = this.props.userTableValue.find((data: any) => data.id == s.id);
      if (user != null) {
        user.update = null;
        this.setState({ userTableValue: this.props.userTableValue });
      }
    });
  };

  render() {
    
    return (
      <div className="p-col-12">


        <OverlayPanel ref={this.giveBalance}  id="overlay_panel" style={{width: '500px'}} showCloseIcon={false}>

            <div className="md-inputfield">
              <span className="p-float-label" style={{padding : '20px'}}>
                <div className="p-grid form-group" >
                  <div className="p-col-12 p-md-12">
                <div className="p-grid form-group" >

                    <div className="p-field-radiobutton" style={{paddingRight : '50px'}}>
                        <RadioButton inputId="city1" name="city1" value="charge" onChange={(e) => this.setState({mode : 'charge'})} checked={this.state.mode === 'charge'}  />
                        <label htmlFor="city1" style={{ paddingTop :'15px', color : 'blue'}}>충전</label>
                    </div>
                    <div className="p-field-radiobutton" style={{paddingRight : '50px'}}>
                        <RadioButton inputId="city2" name="city2" value="exchange" onChange={(e) => this.setState({mode : 'exchange'})} checked={this.state.mode === 'exchange'} />
                        <label htmlFor="city2" style={{paddingLeft : '20px', paddingTop :'15px', color : 'red'}}>환전</label>
                    </div>
                    </div>
                  </div>

                  <InputText id="username" onChange={(e: any) => this.setState({ balance: e.target.value })} value={this.state.balance} />
                  <Button className="p-button-sm p-button-success"  label="지급/회수"onClick={()=>{
                    if(this.state.mode === 'charge'){
                      this.handleDoDeposit()
                    }
                    else if(this.state.mode === 'exchange'){
                      this.handleDoWithdraw()
                    }

                  }}  />
                  </div>
                </span>

              <div className="p-grid form-group" style={{padding : '20px'}} >
                <Button className="p-button-sm p-button-outlined" label="10,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(10000) }) }}/>
                <Button className="p-button-sm p-button-outlined"  label="50,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(50000) }) }} />
                <Button className="p-button-sm p-button-outlined"  label="100,000" onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(100000) }) }} />
                <Button className="p-button-sm p-button-outlined"  label="500,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(500000) }) }}/>
                <Button className="p-button-sm p-button-outlined"  label="1,000,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(1000000) }) }}/>
                <Button className="p-button-sm p-button-danger"  label="정정"onClick={()=>{ this.setState({balance : 0 }) }}  />
              </div>
            </div>

        </OverlayPanel>


        <DataTable
          value={this.props.userTableValue}
          rows={99999}
          sortOrder={-1}
          scrollable={true}
          style={{ textAlign: "center"}}

        >


          <Column
            style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "120px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"가입일"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{convertDate(rowData.regdate)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px", borderRight: '1px solid #d8d8d8' }}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="아이디"
            body={(rowData : any) => {
              return (
                <div style={{ color: rowData.color }}>
                  <div
                    onClick={() => {
                      OpenUserDetail(rowData.id);
                    }}
                  >
                    {rowData.id}
                  </div>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="닉네임"
            body={(rowData : any) => {
              return (
                <div>
                  <div id={"contextMenu"}>{rowData.nick}</div>
                </div>
              );
            }}
          />



          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="이름"
            body={(rowData : any) => {
              return (
                <div>
                  <div id={"contextMenu"}>{rowData.bankowner}</div>
                </div>
              );
            }}
          />

        {
          process.env.REACT_APP_API_USER_NUMBER_HIDE !== 'Y' && (
          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="전화번호"
            body={(rowData : any) => {
              return (
                <div>
                  <div id={"contextMenu"}>{rowData.phone}</div>
                </div>
              );
            }}
          />

          )
          }

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            bodyStyle={{ textAlign: "right"}}
            header={"보유캐시"}
            field="balance"
            body={(rowData : any) => {
              console.log(rowData.refresh )
              return (
                <div>
                  <span>
                    {numTomoeny(rowData.balance + rowData.in_balance)}
                  </span>
                    {
                    rowData.refresh == true ? (
                          <ProgressSpinner
                            strokeWidth={"2"}
                            style={{ width: "10px" }}
                          />
                        ) : (
                          <i
                            className="pi-md-refresh"
                            onClick={() => {
                              this.handleBalanceUpdate(rowData.id);
                              rowData.refresh = true;
                            }}
                          ></i>
                        )
                    }
                </div>
              );
            }}
          />



          <Column
            style={{ textAlign: "center", width: "100px", borderRight: '1px solid #d8d8d8' }}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            bodyStyle={{ textAlign: "right"}}
            header={"금고보유액"}

            body={(rowData : any) => {
              return (
                <div>
                  <span>{rowData.point}</span>
                </div>
              );
            }}
          />

        <Column
            style={{ textAlign: "center", width: "100px", borderRight: '1px solid #d8d8d8' }}
            header={"충전/환전"}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            field="balance"
            body={(rowData : any) => {


              return (
                <Button type="button" label={'충/환전'} onClick={(e : any) => {


                  this.setState({userID : rowData.id})
                  this.giveBalance.current.toggle(e)

                }} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />

              );
            }}
          />

          {
          process.env.REACT_APP_API_TOTAL_DEPOSIT_HIDE !== 'Y' && (
              <Column
                  style={{ textAlign: "center", width: "100px", borderRight: '1px solid #d8d8d8' }}
                  headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                  bodyStyle={{ textAlign: "right"}}
                  header={"총 충전"}

                  body={(rowData : any) => {
                    return (
                      <div>
                        <span>{rowData.total_deposit}</span>
                      </div>
                    );
                  }}
                />
               )
          }
      

            {
          process.env.REACT_APP_API_TOTAL_WITHDRAW_HIDE !== 'Y'  && (
            <Column
              style={{ textAlign: "center", width: "100px", borderRight: '1px solid #d8d8d8' }}
              headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
              bodyStyle={{ textAlign: "right"}}
              header={"총 환전"}

              body={(rowData : any) => {
                return (
                  <div>
                    <span>{rowData.total_withdraw}</span>
                  </div>
                );
              }}
            />
          )
          }
      

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"접속 IP"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{rowData.loginip}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"접속일"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{convertDate(rowData.logindate)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "left", width: "200px", borderRight: '1px solid #d8d8d8' }}
            headerStyle={{ textAlign: "left",width: "200px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"에이전트"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span dangerouslySetInnerHTML={{ __html: ConvertNick(this.props.level, rowData.agent_tree, rowData.agent_tree_ko) }} ></span>
                </div>
              );
            }}
          />

          {/* {RenderReg()} */}
        </DataTable>


      </div>
    );
  }
}
