import React, { Component } from "react";
import { Chart } from "primereact/chart";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";

import { UserService } from "../service/user.service";
import { AgentService } from "../service/agent.service";

import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";

import { Menu } from "primereact/menu";

import { SubPaging } from "../share/sub-paging";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
} from "../utility/help";
import { CONNREFUSED } from "dns";
import { Console } from "console";

const REACT_APP_API_OUTSIDE_BALANCE = process.env.REACT_APP_API_OUTSIDE_BALANCE == null ? 'n' : process.env.REACT_APP_API_OUTSIDE_BALANCE
// console.log(process.env.REACT_APP_API_OUTSIDE_BALANCE)

interface State {
  casionRolling: number;
  slotRolling: number;
  totalBalance: number;
  realBetCount: number;
  realMiniCount: number;
  deposit: number;
  withdraw: number;
  userCount: number;
  todayCount: number;
  connectCount: number;
  losing: number;
  rolling: number;

  losingBenefits: number;
  slotBenefits: number;

  regCount: number;
  totalBetCount: number;

  matchBetCount: number;
  matchWaitCount: number;
  miniBetCount: number;
  miniWaitCount: number;

  pieData: any;

  total: any;
  mini: any;
  miniWin: any;

  match: any;
  matchWait: any;

  slot: any;
  casino: any;
  casinoInOut: any;
  management: any;
  outsideBalance: any;
}

export class Dashboard extends Component<any, State> {
  agentService = new AgentService();
  menu: any;
  constructor(props: any) {
    super(props);
    this.state = {
      management: null,
      outsideBalance: 0,
      casionRolling: 0,
      slotRolling: 0,
      totalBalance: 0,
      realBetCount: 0,
      realMiniCount: 0,
      deposit: 0,
      withdraw: 0,
      regCount: 0,
      userCount: 0,
      todayCount: 0,
      connectCount: 0,
      losing: 0,
      rolling: 0,
      losingBenefits: 0,
      slotBenefits: 0,

      

      matchBetCount: 0,
      matchWaitCount: 0,
      miniBetCount: 0,
      miniWaitCount: 0,
      totalBetCount: 0,

      total: {},
      mini: {},
      match: {},
      matchWait: {},
      miniWin: {},
      casinoInOut: {},

      slot: {},
      casino: {},
      pieData: {
        labels: ["A", "B"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#FFC107", "#03A9F4"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      },
    };
    setInterval(
      function (dash) {
        if (window.location.hash.indexOf("dashboard") >= 0) {
          dash.updateDashborad();
        }
      },
      30000,
      this
    );
    this.updateDashborad();

  }

  componentDidMount() {

  }

  updateDashborad = async() => {
    var dash = await this.agentService.get_dashboard()
      if (dash.status == "success") {
    
        this.setState({
          outsideBalance : dash.outsideBalance,
          userCount : dash.userCount,
          deposit: dash.deposit,
          withdraw: dash.withdraw,

          todayCount: dash.todayCount,
          connectCount: dash.connectCount,
          losing: dash.losing,
          rolling: dash.rolling,
          losingBenefits : dash.losingBenefits ,
          slotBenefits: dash.slotBenefits,

        })
    }
  }

  render() {
    const RenderBase = (data :any) => {

      if(data.benefit != null)
      {
        return (
          <div className="p-col-12 p-md-1 summary-box summary-box-messages">
            <div className="card">
              <div className="summary-box-title">{data.title}</div>
              <p>{numTomoeny(data.info)} ({data.benefit})</p>
            </div>
          </div>
        );
      }
      
      return (
        <div className="p-col-12 p-md-1 summary-box summary-box-messages">
          <div className="card">
            <div className="summary-box-title">{data.title}</div>
            <p>{numTomoeny(data.info)}</p>
          </div>
        </div>
      );
    };

    const RenderBalance = (data : any) => {
      let depositBalance = this.state.deposit;

      let withdrawBalance = this.state.withdraw;

      let pieData = {
        labels: ["충전", "환전"],
        datasets: [
          {
            data: [depositBalance, withdrawBalance],
            backgroundColor: ["#ff0000", "#03A9F4"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      };

      return (
        <div className="card">
          <div className="card-title">{data.text}</div>
          {/* <div className="overview-numbers">2,449,570</div> */}
          <div className="p-grid">
            <div className="p-col-7 connection-chart">
              <Chart type="doughnut" data={pieData} height="150" />
            </div>
            <div className="p-col-5 connection-chart-legend">
              <div className="p-grid">
                <div className="p-col-6">
                  <span className="dot dot-red"></span>충전
                </div>
                <div className="p-col-6">{numTomoeny(depositBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>환전
                </div>
                <div className="p-col-6">{numTomoeny(withdrawBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>차액
                </div>
                <div className="p-col-6">
                  {numTomoeny(depositBalance - withdrawBalance)}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const RenderWinLose = (data : any) => {
      let winBalance =
        data.balance == null
          ? 0
          : data.balance.win == null
            ? 0
            : data.balance.win.balance == null
              ? 0
              : data.balance.win.balance;

      let betBalance =
        data.balance == null
          ? 0
          : data.balance.bet == null
            ? 0
            : data.balance.bet.balance == null
              ? 0
              : data.balance.bet.balance;

      let pieData = {
        labels: ["이긴금액", "배팅금액"],
        datasets: [
          {
            data: [winBalance, betBalance],
            backgroundColor: ["#FFC107", "#03A9F4"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      };

      return (
        <div className="card">
          <div className="card-title">{data.text}</div>
          {/* <div className="overview-numbers">2,449,570</div> */}
          <div className="p-grid">
            <div className="p-col-7 connection-chart">
              <Chart type="doughnut" data={pieData} height="150" />
            </div>
            <div className="p-col-5 connection-chart-legend">
              <div className="p-grid">
                <div className="p-col-6">
                  <span className="dot dot-orange"></span>배팅금액
                </div>
                <div className="p-col-6">{numTomoeny(betBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-red"></span>이긴금액
                </div>
                <div className="p-col-6">{numTomoeny(winBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>합
                </div>
                <div className="p-col-6">
                  {numTomoeny(betBalance - winBalance)}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const RenderCustom = (data : any) => {
      let winBalance =
        data.casinoInOut == null
          ? 0
          : data.casinoInOut.in == null
            ? 0
            : data.casinoInOut.in.balance == null
              ? 0
              : data.casinoInOut.in.balance;

      let winCount =
        data.casinoInOut == null
          ? 0
          : data.casinoInOut.in == null
            ? 0
            : data.casinoInOut.in.count == null
              ? 0
              : data.casinoInOut.in.count;

      let betBalance =
        data.casinoInOut == null
          ? 0
          : data.casinoInOut.out == null
            ? 0
            : data.casinoInOut.out.balance == null
              ? 0
              : data.casinoInOut.out.balance;
      let betCount =
        data.casinoInOut == null
          ? 0
          : data.casinoInOut.out == null
            ? 0
            : data.casinoInOut.out.count == null
              ? 0
              : data.casinoInOut.out.count;

      let pieData = {
        labels: ["스포>카지", "카지>스포"],
        datasets: [
          {
            data: [winBalance, betBalance],
            backgroundColor: ["#990000", "#0066ff"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      };

      return (
        <div className="card">
          <div className="card-title">{data.text}</div>
          {/* <div className="overview-numbers">2,449,570</div> */}
          <div className="p-grid">
            <div className="p-col-7 connection-chart">
              <Chart type="doughnut" data={pieData} height="150" />
            </div>
            <div className="p-col-5 connection-chart-legend">
              <div className="p-grid">
                <div className="p-col-6">
                  <span className="dot dot-orange"></span>{"스포>카지"}
                </div>
                <div className="p-col-6">
                  {numTomoeny(-1 * betBalance)} ({betCount}){" "}
                </div>

                <div className="p-col-6">
                  <span className="dot dot-red"></span>{"카지>스포"}
                </div>
                <div className="p-col-6">
                  {numTomoeny(-1 * winBalance)} ({winCount}){" "}
                </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>합
                </div>
                <div className="p-col-6">
                  {numTomoeny(-1 * (betBalance + winBalance))}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    // if (this.state.management == null) {
    //   return <ProgressSpinner />;
    // }

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="p-grid dashboard-grid">

                {RenderBase({ title: "알보유", info: this.state.outsideBalance, })}


                {RenderBase({ title: "회원수", info: this.state.userCount })}

                {RenderBase({title: "현재접속", info: this.state.connectCount,})}


                {/* {RenderBase({title: "루징", info: this.state.losing, benefit: this.state.slotBenefits})} */}

                {RenderBase({title: "롤링", info: this.state.rolling, benefit: this.state.losingBenefits })}



           
      
              </div>
            </div>
{/* 
            <div className="p-col-12">
              <div className="p-grid dashboard-grid">
                <div className="p-col-12 p-xl-4 overview-box connections">
                  {RenderBalance({ text: "충전/환전" })}
                </div>

                {this.state.management.sportpre === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "대기중 스포츠 Win/Bet",
                      balance: this.state.matchWait,
                    })}
                  </div>
                ) : (
                    <></>
                  )}

                {this.state.management.mini === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "대기중 미니게임 Win/Bet",
                      balance: this.state.miniWin,
                    })}
                  </div>
                ) : (
                    <></>
                  )}

                {this.state.management.sportpre === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderCustom({
                      text: "카지노 머니이동",
                      casinoInOut: this.state.casinoInOut,
                    })}
                  </div>
                ) : (
                    <></>
                  )}

                {this.state.management.sportpre === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "스포츠 Win/Bet",
                      balance: this.state.match,
                    })}
                  </div>
                ) : (
                    <></>
                  )}

                {this.state.management.mini === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "미니게임 Win/Bet",
                      balance: this.state.mini,
                    })}
                  </div>
                ) : (
                    <></>
                  )}

                {this.state.management.sportpre === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "스포츠 + 미니게임  토탈 Win/Lose",
                      balance: this.state.total,
                    })}
                  </div>
                ) : (
                    <></>
                  )}

                {this.state.management.casino === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "카지노 Win/Lose",
                      balance: this.state.casino,
                    })}
                  </div>
                ) : (
                    <></>
                  )}

                {this.state.management.slot === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "슬롯 Win/Lose",
                      balance: this.state.slot,
                    })}
                  </div>
                ) : (
                    <></>
                  )}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
