import React, { Component } from "react";
import Pagination from "react-js-pagination";

interface CustomProps {
  active: number;
  take: number;
  count: number;
  handlePageChange: (event: any) => any;
}
export class CustomPagination extends Component<CustomProps> {
  constructor(props: any) {
    super(props);
  }

  render = () => {
    return (
      <Pagination
        activePage={this.props.active}
        itemsCountPerPage={this.props.take}
        totalItemsCount={this.props.count}
        onChange={this.props.handlePageChange}
      />
    );
  };
}
