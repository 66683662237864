import React, { Component } from 'react';

import GitInfo from 'react-git-info/macro';


export class AppFooter extends Component {

    render() {
        const gitInfo = GitInfo();
        // console.log(gitInfo.branch);
        // console.log(gitInfo.tags);
        // console.log(gitInfo.commit.date);
        // console.log(gitInfo.commit.hash);
        // console.log(gitInfo.commit.message);
        // console.log(gitInfo.commit.shortHash);
        return  <div className="layout-footer">
                    <div className="p-grid">
                        <div className="p-col">
                            <img src="assets/layout/images/logo-white.svg" alt="sapphire-layout"/>
                            <div className="layout-footer-appname">V3  {gitInfo.commit.date} {gitInfo.commit.hash} {gitInfo.branch}</div>
                        </div>
                        <div className="p-col p-col-align-right">
                            <span className="pi-md-copyright"></span>
                            <span>All Rights Reserved</span>
                        </div>
                    </div>
                </div>
    }
}