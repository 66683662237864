import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import useLocation from "react-router-dom";

interface AppProps {
  match: any;
  location: any;
  history: any;
}

export class AppBreadcrumb extends Component<AppProps> {
  static propTypes = {
    match: PropTypes.object
  };

  render() {
    const { location } = this.props;

    return (
      <div className="layout-breadcrumb">
        <ul>
          <li>
            <button
              className="p-link"
              onClick={() => {
                window.location.reload();
              }}
            >
              <i className="material-icons">home</i>
            </button>
          </li>
          <li>
            <i className="material-icons">chevron_right</i>
          </li>
          <li>{location.pathname.split("/")[1]} </li>
        </ul>
      </div>
    );
  }
}
