import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor
} from "../../utility/help";

interface Props {
  SearchData: (field: any, sort: any) => any;
  GetConnectUserList: () => any;
  
}

interface State {
  selectBox: any[];
  dropdownItem: any;
  seach: any;
  userType: any;

  userValue: any;
}

export class HelpBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seach: "",
      dropdownItem: null,
      selectBox: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "닉네임", value: "nick" },
        { label: "이름", value: "bankowner" },
        { label: "추천인", value: "friendId" },
        { label: "전화번호", value: "mobile" },
        { label: "에이전트", value: "agent" },
        { label: "IP 검색", value: "loginip" }
      ],
      userType: { name: "모든 회원", value: 'all' },
      userValue: [
        { name: "모든 회원", value: 'all' },
        { name: "현재 접속중 회원", value: 'connect' },
      ],
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="p-col-12 ">
        <div className="p-grid form-group">
          <div className="p-col-12 p-md-4">
            <span className="md-inputfield">
              <Dropdown
                options={this.state.selectBox}
                value={this.state.dropdownItem}
                onChange={event => this.setState({ dropdownItem: event.value })}
                autoWidth={false}
              />{" "}
            </span>
          </div>
          <div className="p-col-12 p-md-4">
            <span className="md-inputfield">
              <InputText
                value={this.state.seach}
                onChange={(e: any) => this.setState({ seach: e.target.value })}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    this.props.SearchData(
                      this.state.dropdownItem,
                      this.state.seach
                    );
                  } else {
                  }
                }}
              />
              <label>검색</label>
            </span>
          </div>
          <div className="p-col-12 p-md-4">
            <span className="md-inputfield">
              <Button
                label="검색"
                onClick={() => {
                  this.props.SearchData(
                    this.state.dropdownItem,
                    this.state.seach
                  );
                }}
              />
            </span>
          </div>
          <div className="p-grid form-group">

          <SelectButton
            style={{ paddingTop: 10 }}
            optionLabel="name"
            value={this.state.userType}
            options={this.state.userValue}
            onChange={(e : any) => {
                if(e.target.value != null){

                  this.setState({userType : e.target.value})

                  if(e.target.value === 'all'){
                    this.props.SearchData(
                      this.state.dropdownItem,
                      this.state.seach
                    );
                  }
                  else if(e.target.value === 'connect'){
                    this.props.GetConnectUserList();
                  }
                  
                }
            }}
            />
        </div>
        </div>
      </div>
    );
  }
}
