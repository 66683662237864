import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus
} from "../../utility/help";

interface MiniProps {
  bets: any;
}

interface MiniState {
  active: any;
  deposits: any;
  page: any;
  pages: any;
  field: any;
}

export class BetMiniTable extends Component<MiniProps, MiniState> {
  constructor(props: MiniProps) {
    super(props);
    this.state = {
      active: 0,
      deposits: [],
      page: 0,
      pages: {},
      field: ""
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount = () => {};

  // header = (up, down) => {
  //   return (
  //     <div>
  //       <span>{up}</span>
  //       <br />
  //       <span>{down}</span>
  //       <br />
  //     </div>
  //   );
  // };

  handlePageChange = (e: any) => {
    this.setState({ active: e });
  };

  render = () => {
    // const mode = this.props.mode;
    const bets = this.props.bets;
    // const OnEditItem = this.props.OnEditItem;
    // const OnSelectItem = this.props.OnSelectItem;

    return (
      <DataTable
        value={bets}
        rows={20}
        scrollable={true}
        style={{ width: "1600px" }}
      >
        <Column style={{ textAlign: "center" }} header="아이디" field="id" />

        <Column
          style={{ textAlign: "center" }}
          field="bet"
          header="배팅금"
          body={(rowData: any, column: any) => {
            return (
              <div>
                <div>{numTomoeny(rowData.bet)}</div>
              </div>
            );
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          field="win"
          header="이긴금액"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.win)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="보유금"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.balance)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="회사"
          body={(rowData: any, column: any) => {
            return <div>{rowData.categoryName}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="배팅타입"
          body={(rowData: any, column: any) => {
            return <div>{rowData.marketName}</div>;
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          header="유저배팅"
          body={(rowData: any, column: any) => {
            return <div>{rowData.betName}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="결과"
          body={(rowData: any, column: any) => {
            return <div>{rowData.resultName}</div>;
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          header="에이전트"
          body={(rowData: any, column: any) => {
            return (
              <div>
                <div>{rowData.agent_tree}</div>
                <br />
                <div>{rowData.agent_roll}</div>
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="배팅일"
          body={(rowData: any, column: any) => {
            return (
              <div style={{ color: getDepositColor() }}>
                {convertDate(rowData.gamedate)}
              </div>
            );
          }}
        />
      </DataTable>
    );
  };
}
