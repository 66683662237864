import React from "react";
import Pagination from "react-js-pagination";

interface Props {
  limit: number;
  activePage: number;
  maxCount: number;

  MoveSel: (page: number) => any;
}

export class SubPaging extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // this.state = { pagings: [] };
    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }
  render() {
    let limit = this.props.limit;
    let activePage = this.props.activePage;
    let maxCount = this.props.maxCount;
    let MoveSel =
      this.props.MoveSel == undefined ? () => {} : this.props.MoveSel;

    return (
      <Pagination
        activePage={activePage}
        itemsCountPerPage={limit}
        totalItemsCount={maxCount}
        onChange={MoveSel}
      />
    );
  }
}
