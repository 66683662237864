import React, { Component } from "react";

// import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
// import { NodeService } from "../service/NodeService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { confirmAlert } from "react-confirm-alert"; // Import

import { AgentBasic } from "../share/agentdetail/agent-basic";
import { AgentBalanceLog } from "../share/agentdetail/agent-balance-log";

import { AgentBalance } from "../share/agentdetail/agnet-balance";
import { AgentBenefit } from "../share/agentdetail/agent-benefit";

// import { UserCalculate } from "../share/userdetail/user-calculate";

import { AgentService } from "../service/agent.service";
import { BalanceService } from "../service/balance.service";
import { UserService } from "../service/user.service";

let REACT_APP_API_CUSTOM = process.env.REACT_APP_API_CUSTOM;

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converAgentStatus,
  UUID
} from "../utility/help";

interface Props {
    userID: string;
}

interface State {
  detail: any;
  checkboxValue: any[];
  eventText: any;
  eventType: any;
  giveBalance: any;
  changePW: any;

  rollingSlot : any
  rollingCasino : any
  code :String
}

export class UserDetail extends Component<Props, State> {
  userService = new UserService();
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
      code : UUID(),
      changePW : '',
      detail: {},
      checkboxValue: [],
      eventType: [
        { label: "지급", value: "deposit" },
        { label: "회수", value: "withdraw" },
      ],
      eventText: "deposit",
      giveBalance: 0,
      rollingSlot : 0,
      rollingCasino : 0
    };


    this.handleUser()
    // this.handlePageChange = this.handlePageChange.bind(this);
  }

  handleUser = async()  =>{
    if(REACT_APP_API_CUSTOM === 'Y'){
      const detail = await this.userService.agent_get_user_detail(this.props.userID)
      if(detail.status === 'success'){
        this.setState({detail : detail.user})
      }
    }
  }


  getUserBenefit = async()  =>{
    const detail = await this.userService.get_user_benefit(this.props.userID)
    if(detail.status === 'success'){
      this.setState({rollingSlot : detail.rollingSlot, rollingCasino : detail.rollingCasino})
    }

  }




  componentDidMount() {
    this.getUserBenefit()
  }


  handleChangePW() {
    confirmAlert({
      title: "비밀번호",
      message: "해당 비밀번호를 변경하시겠습니까??.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.userService
              .agent_change_user_pw(this.props.userID, this.state.changePW)
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {
            this.setState({code : UUID()})

          },
        },
      ],
    });
    return;
  }



  admin_update_user_benefit_casino() {
    confirmAlert({
      title: "롤링 변경",
      message: "카지노 유저 롤링 프로테이지를 변경하시겠습니까??.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.userService
              .admin_update_user_benefit_casino(this.props.userID, this.state.rollingCasino)
              .then((s: any) => {

                if (s.status === "success") {
                  confirmAlert({
                    title: "변경",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else if (s.status === "roll") {
                  confirmAlert({
                    title: "변경",
                    message: "롤링 프로테이지를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "변경",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {
            this.setState({code : UUID()})

          },
        },
      ],
    });
    return;
  }




  admin_update_user_benefit_slot() {
    confirmAlert({
      title: "롤링 변경",
      message: "카지노 유저 롤링 프로테이지를 변경하시겠습니까??.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.userService
              .admin_update_user_benefit_slot(this.props.userID, this.state.rollingSlot)
              .then((s: any) => {

                if (s.status === "success") {
                  confirmAlert({
                    title: "변경",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else if (s.status === "roll") {
                  confirmAlert({
                    title: "변경",
                    message: "롤링 프로테이지를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "변경",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  handleDoDeposit() {
    confirmAlert({
      title: "지급",
      message: "해당 벨런스를 유저에게 지급하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_deposit_to_user({balance: this.state.giveBalance, id : this.props.userID, code:  this.state.code})

              .then((s: any) => {
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {
            this.setState({code : UUID()})

          },
        },
      ],
    });
    return;
  }
  handleDoWithdraw() {
    confirmAlert({
      title: "회수",
      message: "해당 벨런스를 유저에게 회수하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
            .agent_withdraw_to_user({balance: this.state.giveBalance, id : this.props.userID, code:  this.state.code})

              // .agent_withdraw_to_user(this.state.giveBalance, this.props.userID)
              .then((s: any) => {
                if (s.status === "success") {
                  confirmAlert({
                    title: "회수",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "회수",
                    message: "유저 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "회수",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.setState({code : UUID()})

                        },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  render() {


    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12 p-md-3">
              <div className="card card-w-title">
                <div className="card-title">유저 지급</div>
                <div className="p-grid">

                  <div className="p-col-12 p-md-5">
                    <label htmlFor="input">아이디</label>
                  </div>
                  <div className="p-col-12 p-md-7">
                    <p style={{ textAlign: "right", marginBottom: "0px" }}>
                      {this.props.userID}
                    </p>
                  </div>
                  {
                   REACT_APP_API_CUSTOM === 'Y' && (
                     <>
                      <div className="p-col-12 p-md-5">
                        <label htmlFor="input">비번</label>
                      </div>
                      <div className="p-col-12 p-md-7">
                        <p style={{ textAlign: "right", marginBottom: "0px" }}>
                          {this.state.detail.pw}
                        </p>
                      </div>
                     </>
                   )
                  }



                </div>
              </div>
            </div>


            <div className="p-col-12 p-md-9">

                <div className="card card-w-title">
                  <div className="p-fluid p-grid p-formgrid">
                    <div className="p-field p-col-12 p-lg-3">
                      <Dropdown
                             options={this.state.eventType}
                             value={this.state.eventText}
                             onChange={(event: any) =>
                             this.setState({
                                 eventText: event.value,
                             })
                             }
                             style={{ width: "12em" }}

                         />
                        </div>
                      <div className="p-field p-col-12 p-md-4">

                             <InputText
                                id="username"
                                value={this.state.giveBalance}
                                onChange={(event: any) => {
                                try {
                                    if (
                                    isNaN(
                                        Number(event.target.value.split(",").join(""))
                                    ) === false
                                    ) {
                                    this.setState({
                                        giveBalance: Number(event.target.value),
                                    });
                                    }
                                } catch (ex) {}
                                }}
                            />
                            <label htmlFor="username">지급금액</label>

                        </div>
                        <div className="p-field p-col-12 p-lg-3">
                            <Button
                            label="확인"
                            onClick={() => {
                                console.log(this.state.eventText);
                                if (this.state.eventText === "deposit") {
                                this.handleDoDeposit();
                                } else {
                                this.handleDoWithdraw();
                                }
                            }}
                            />
                        </div>
                        </div>
                    </div>
                    {
              process.env.REACT_APP_API_USER_SLOT_ROLLING === 'Y' && (
                    <div className="card card-w-title">
                      <div className="p-fluid p-grid p-formgrid">
                        <div className="p-field p-col-12 p-lg-3">슬롯 롤링 </div>
                          <div className="p-field p-col-12 p-md-4">

                                <InputText
                                    id="username"
                                    value={this.state.rollingSlot}
                                    placeholder="슬롯 롤링 퍼센트"

                                    onChange={(event: any) => {
                                      this.setState({rollingSlot: event.target.value});
                                    }}
                                />

                            </div>
                            <div className="p-field p-col-12 p-lg-3">
                                <Button
                                label="확인"
                                onClick={() => {
                                    this.admin_update_user_benefit_slot();
                                }}
                                />
                            </div>
                            </div>
                        </div>
                                      )}

                    { process.env.REACT_APP_API_USER_CASINO_ROLLING === 'Y' && (
                        <div className="card card-w-title">
                          <div className="p-fluid p-grid p-formgrid">
                            <div className="p-field p-col-12 p-lg-3">카지노 롤링</div>
                              <div className="p-field p-col-12 p-md-4">

                                    <InputText
                                        id="username"
                                        placeholder="카지노 롤링 퍼센트"

                                        value={this.state.rollingCasino}
                                        onChange={(event: any) => {
                                          this.setState({rollingCasino: event.target.value});
                                        }}
                                    />

                                </div>
                                <div className="p-field p-col-12 p-lg-3">
                                    <Button
                                    label="확인"
                                    onClick={() => {
                                        this.admin_update_user_benefit_casino();
                                    }}
                                    />
                                </div>
                                </div>
                        </div>
              )}
            {
              REACT_APP_API_CUSTOM === 'Y' && (
                <div className="card card-w-title">
                  <div className="p-fluid p-grid p-formgrid">
                    <div className="p-field p-col-12 p-lg-3">비밀변호 변경</div>
                      <div className="p-field p-col-12 p-md-4">

                             <InputText
                                id="username"
                                value={this.state.changePW}
                                onChange={(event: any) => {
                                  this.setState({changePW: event.target.value});
                                }}
                            />
                            <label htmlFor="username">비밀변호</label>

                        </div>
                        <div className="p-field p-col-12 p-lg-3">
                            <Button
                            label="확인"
                            onClick={() => {
                                this.handleChangePW();
                            }}
                            />
                        </div>
                        </div>
                    </div>
              )
            }

            </div>
          </div>
        </div>
      </div>
    );
  }
}
